<template>
  <a-modal v-model="visible" :after-close="afterClose" :destroy-on-close="true" :footer="null" :title="chartKey.label"
    :width="1080">
    <a-form-model layout="inline" style="margin-bottom: 16px;">
      <a-form-model-item>
        <a-range-picker :allow-clear="false" :default-value="dates" :disabled-date="disabledDate" :ranges="ranges"
          :show-time="{ format: 'HH:mm' }" format="YYYY-MM-DD HH:mm" @ok="v => { this.dates = v }"></a-range-picker>
      </a-form-model-item>
      <a-form-model-item v-if="chartType === 'chart'">
        <a-checkbox v-model="checked.yesterday">
          <a-space style="user-select: none;">
            <span>环比</span>
            <a-tooltip title="昨天同时段">
              <a-icon type="question-circle"></a-icon>
            </a-tooltip>
          </a-space>
        </a-checkbox>
        <a-checkbox v-model="checked.lastWeek">
          <a-space style="user-select: none;">
            <span>同比</span>
            <a-tooltip title="上周同时段">
              <a-icon type="question-circle"></a-icon>
            </a-tooltip>
          </a-space>
        </a-checkbox>
      </a-form-model-item>
    </a-form-model>

    <line-chart v-if="chartType === 'chart'" :chart-key="chartKey" :datetime-range="dates" :source-type="sourceType"
      :compareYesterday="checked.yesterday" :compareLastWeek="checked.lastWeek" style="height: 400px;">
    </line-chart>
    <table-chart v-else :datetime-range="dates" :chartKey="chartKey" :sourceType="sourceType" :scroll="{ y: 500 }">
    </table-chart>
  </a-modal>
</template>

<script>
import moment from 'moment'
import { getDatetimeRanges } from '@/utils'
import LineChart from './LineChart'
import TableChart from './TableChart'

export default {
  name: 'ChartModal',
  components: { LineChart, TableChart },
  props: {
    datetimeRange: { type: Array, required: true },
    sourceType: { type: String, required: true },
    chartKey: { type: Object, required: true }
  },
  data () {
    return {
      visible: false,
      dates: this.datetimeRange || [],
      ranges: getDatetimeRanges(),
      checked: { yesterday: false, lastWeek: false },
      chartType: 'chart'
    }
  },
  watch: {
    datetimeRange () {
      this.dates = this.datetimeRange
    }
  },
  methods: {
    show () {
      if (this.chartKey.value_type === 'numeric_float' || this.chartKey.value_type === 'numeric_unsigned') { this.chartType = 'chart' } else this.chartType = 'table'
      this.visible = true
    },
    afterClose () {
      this.checked = { yesterday: false, lastWeek: false }
    },
    disabledDate (date) {
      return date && date > moment().endOf('day')
    }
  }
}
</script>
