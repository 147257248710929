<template>
  <div>
    <a-table :columns="columns"
             :data-source="tableData"
             :loading="loading"
             :pagination="false"
             row-key="timestamp"
             :scroll="scroll"
             style="margin-bottom: 16px;min-height:400px">
      <template slot="timestamp"
                slot-scope="record">{{ record|date }}</template>
      <template slot="value"
                slot-scope="record">
        {{record}}</template>
    </a-table>
  </div>
</template>

<script>
import moment from 'moment'
import { humanValue, getSourceMonitorHistoryFunc } from '@/utils'

export default {
  name: 'TableChart',
  props: {
    chartKey: { type: Object, required: true },
    datetimeRange: { type: Array, required: true },
    sourceType: { type: String, required: true },
    scroll: { type: Object, required: true }
  },
  computed: {
    historyFunc () {
      return getSourceMonitorHistoryFunc(this.sourceType)
    },
    chartId () {
      return `${this.chartKey.key}.chart`
    }
  },
  data () {
    return {
      loading: false,
      tableData: [],
      columns: [
        { dataIndex: 'timestamp', title: '时间', width: 180, scopedSlots: { customRender: 'timestamp' } },
        { dataIndex: 'value', title: '值', width: 450, scopedSlots: { customRender: 'value' } }
      ],
      form: { page: 1, page_size: 10 },
      total: 0
    }
  },
  filters: {
    humanValue (v) {
      return humanValue(v)
    },
    date (v) {
      return moment(v).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  methods: {
    fetch () {
      this.loading = true
      this.historyFunc(this.$route.params.id, {
        key: this.chartKey.key,
        datetime_from: this.datetimeRange[0].format('YYYY-MM-DD HH:mm'),
        datetime_to: this.datetimeRange[1].format('YYYY-MM-DD HH:mm')
      }).then(res => {
        this.tableData = res.data.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.fetch()
  },
  watch: {
    datetimeRange () {
      this.fetch()
    }
  }
}
</script>

<style lang="less">
</style>
