<template>
  <a-card :head-style="{ border: 'none' }" :hoverable="true" size="small" :title="chartKey.label">
    <a-space slot="extra">
      <a-icon slot="extra" type="fullscreen" @click="$refs.chartModal.show()"></a-icon>
      <a-icon v-if="sourceType!=='web'" type="close" slot="extra" style="cursor:pointer" @click="closeModal(chartKey)" />
    </a-space>

    <card-chart v-if="(chartKey.value_type === 'numeric_float' || chartKey.value_type === 'numeric_unsigned')"
      :datetime-range="datetimeRange" :chartKey="chartKey" :sourceType="sourceType"></card-chart>
    <table-chart v-else :datetime-range="datetimeRange" :chartKey="chartKey" :sourceType="sourceType"
      style="height: 320px;" :scroll="{ y: 260, x: 200 }"></table-chart>

    <chart-modal ref="chartModal" :datetime-range="datetimeRange" :chartKey="chartKey" :source-type="sourceType">
    </chart-modal>
  </a-card>
</template>

<script>
import CardChart from './modules/CardChart'
import ChartModal from './modules/ChartModal.vue'
import TableChart from './modules/TableChart'

export default {
  name: 'MonitorChartCard',
  components: {
    CardChart,
    ChartModal,
    TableChart
  },
  props: {
    datetimeRange: { type: Array, required: true },
    chartKey: { type: Object, required: true },
    sourceType: { type: String, required: true }
  },
  methods: {
    closeModal (chartKey) {
      this.$emit('updatekey', chartKey)
    }
  }
}
</script>
