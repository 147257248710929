<template>
  <a-spin :spinning="spinning">
    <div :id="chartId" style="height: 320px;"></div>
  </a-spin>
</template>

<script>
import { Chart } from '@antv/g2'
import { humanValue, getSourceMonitorHistoryFunc } from '@/utils'

const chart = {}

export default {
  name: 'CardChart',
  props: {
    chartKey: { type: Object, required: true },
    datetimeRange: { type: Array, required: true },
    sourceType: { type: String, required: true }
  },
  data () {
    return {
      spinning: false
    }
  },
  computed: {
    historyFunc () {
      return getSourceMonitorHistoryFunc(this.sourceType)
    },
    chartId () {
      return `${this.chartKey.key}.chart`
    }
  },
  mounted () {
    chart[this.chartKey.key] = new Chart({
      container: this.chartId,
      autoFit: true,
      appendPadding: [8, 10, 0, 0]
    })
    chart[this.chartKey.key].scale({
      timestamp: {
        type: 'time',
        mask: 'YYYY-MM-DD HH:mm'
      },
      value: {
        min: 0,
        nice: true,
        alias: '值',
        formatter: value => humanValue(value)
      }
    })
    chart[this.chartKey.key].tooltip({
      showCrosshairs: true
    })
    this.render()
  },
  methods: {
    render (isUpdate = false) {
      if (isUpdate) {
        chart[this.chartKey.key].clear()
        chart[this.chartKey.key].annotation().clear(true)
      }
      this.spinning = true
      this.historyFunc(this.$route.params.id, {
        key: this.chartKey.key,
        datetime_from: this.datetimeRange[0].format('YYYY-MM-DD HH:mm'),
        datetime_to: this.datetimeRange[1].format('YYYY-MM-DD HH:mm')
      }).then(res => {
        const data = res.data.data
        chart[this.chartKey.key].data(data)
        chart[this.chartKey.key].line().position('timestamp*value')
        chart[this.chartKey.key].area().position('timestamp*value')
        if (data.length !== 0) {
          let max = data[0]
          let min = data[0]
          for (const datum of data) {
            if (datum.value > max.value) max = datum
            if (datum.value < min.value) min = datum
          }
          chart[this.chartKey.key].annotation().dataMarker({
            direction: 'downward',
            top: true,
            position: [max.timestamp, max.value],
            text: {
              content: `峰值: ${humanValue(max.value)}`
            }
          })
          if (max.timestamp !== min.timestamp) {
            chart[this.chartKey.key].annotation().dataMarker({
              top: true,
              position: [min.timestamp, min.value],
              text: {
                content: `谷值: ${humanValue(min.value)}`
              }
            })
          }
        }
      }).finally(() => {
        chart[this.chartKey.key].render(isUpdate)
        this.spinning = false
      })
    }
  },
  watch: {
    datetimeRange () {
      this.render(true)
    }
  }
}
</script>
