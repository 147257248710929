<template>
  <a-spin :spinning="spinning">
    <div :id="chartId"></div>
  </a-spin>
</template>

<script>
import moment from 'moment'
import { Chart } from '@antv/g2'
import { humanValue, getSourceMonitorHistoryFunc } from '@/utils'

let chart = null

export default {
  name: 'LineChart',
  props: {
    compareLastWeek: { type: Boolean, default: false },
    compareYesterday: { type: Boolean, default: false },
    datetimeRange: { type: Array, required: true },
    chartKey: { type: Object, required: true },
    sourceType: { type: String, required: true }
  },
  data () {
    return {
      spinning: false,
      y: 0,
      offset: 0
    }
  },
  computed: {
    chartId () {
      return `${this.chartKey.key}.chart.modal`
    },
    historyFunc () {
      return getSourceMonitorHistoryFunc(this.sourceType)
    }
  },
  mounted () {
    this.render()
  },
  methods: {
    async render () {
      if (chart) chart.destroy()
      chart = new Chart({
        container: this.chartId,
        autoFit: true,
        height: 400,
        appendPadding: [8, 8, 0, 0]
      })
      chart.scale({
        timestamp: { type: 'time', mask: 'YYYY-MM-DD HH:mm' },
        value: {
          min: 0,
          nice: true,
          alias: '值',
          formatter: value => humanValue(value)
        }
      })
      chart.tooltip({ showCrosshairs: true })
      chart.removeInteraction('tooltip')

      const datetimeRangeList = [this.datetimeRange]
      if (this.compareYesterday) {
        datetimeRangeList.push([
          moment(this.datetimeRange[0]).subtract(1, 'day'),
          moment(this.datetimeRange[1]).subtract(1, 'day')
        ])
      }
      if (this.compareLastWeek) {
        datetimeRangeList.push([
          moment(this.datetimeRange[0]).subtract(1, 'week'),
          moment(this.datetimeRange[1]).subtract(1, 'week')
        ])
      }
      const offset = 1 / datetimeRangeList.length
      this.spinning = true

      for (let i = 0; i < datetimeRangeList.length; i++) {
        const view = chart.createView({
          region: {
            start: {
              x: 0,
              y: offset * i
            },
            end: {
              x: 1,
              y: offset * i + offset
            }
          }
        })
        await this.historyFunc(this.$route.params.id, {
          key: this.chartKey.key,
          datetime_from: datetimeRangeList[i][0].format('YYYY-MM-DD HH:mm'),
          datetime_to: datetimeRangeList[i][1].format('YYYY-MM-DD HH:mm')
        }).then(res => {
          const data = res.data.data
          view.data(data)
          view.interaction('tooltip')
          view.interaction('sibling-tooltip')
          view.line().position('timestamp*value')
          view.area().position('timestamp*value')
          if (data.length !== 0) {
            let max = data[0]
            let min = data[0]
            for (const datum of data) {
              if (datum.value > max.value) max = datum
              if (datum.value < min.value) min = datum
            }
            view.annotation().dataMarker({
              direction: 'downward',
              top: true,
              position: [max.timestamp, max.value],
              text: {
                content: `峰值: ${humanValue(max.value)}`
              }
            })
            if (max.timestamp !== min.timestamp) {
              view.annotation().dataMarker({
                top: true,
                position: [min.timestamp, min.value],
                text: {
                  content: `谷值: ${humanValue(min.value)}`
                }
              })
            }
          }
        })
      }
      chart.render()
      this.spinning = false
    }
  },
  watch: {
    datetimeRange () {
      this.render()
    },
    compareYesterday () {
      this.render()
    },
    compareLastWeek () {
      this.render()
    }
  }
}
</script>
