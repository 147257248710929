<template>
  <div>
    <a-form-model :model="form">
      <a-row :gutter="16">
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="severity">
            <severity-select v-model="form.severity"></severity-select>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="name">
            <a-input v-model="form.name" :allow-clear="true" :max-length="128" placeholder="请输入告警名称"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item>
            <a-button icon="search" type="primary" @click="search">查询</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      row-key="id"
      :row-selection="rowSelection"
      :scroll="{ x: scrollX }"
      @change="change"
      style="margin-bottom: 16px;"
    >
      <template slot="name" slot-scope="record">
        <a-tooltip placement="topLeft" :title="record.name">
          <a @click="$router.push({ name: 'AlertEventDetail', params: { id: record.id } })">{{ record.name }}</a>
        </a-tooltip>
      </template>
      <template slot="severity" slot-scope="record">
        <severity-tag :severity="record"></severity-tag>
      </template>
      <template slot="duration" slot-scope="record">{{ duration(record.triggered_at, record.recovered_at) }}</template>
      <template slot="recovered" slot-scope="record">
        <boolean-status-badge false-name="告警" true-name="恢复" :value="record"></boolean-status-badge>
      </template>
    </a-table>

    <div style="height: 32px;">
      <span v-if="rowSelection.selectedRowKeys.length" style="line-height: 32px;">{{ `已选中 ${rowSelection.selectedRowKeys.length} 条` }}</span>
      <a-pagination
        v-model="form.page"
        :pageSize="form.page_size"
        :show-size-changer="true"
        :show-total="t => `共 ${t} 条`"
        :total="total"
        @change="page => { this.form.page = page; this.fetch() }"
        @showSizeChange="(_, pageSize) => { this.form.page = 1; this.form.page_size = pageSize; this.fetch() }"
        style="float: right;"
      ></a-pagination>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { getAlertEventList } from '@/api/alert-event'
import BooleanStatusBadge from '@/components/badge/BooleanStatusBadge'
import SeveritySelect from '@/components/select/SeveritySelect'
import SeverityTag from '@/components/tag/SeverityTag'
import { humanTime } from '@/utils'

export default {
  name: 'AlertEventTab',
  components: {
    BooleanStatusBadge,
    SeveritySelect,
    SeverityTag
  },
  props: {
    sourceId: { type: String, required: true },
    sourceType: { type: String, required: true }
  },
  data () {
    return {
      form: {
        source_id: this.sourceId,
        source_type: this.sourceType,
        page: 1,
        page_size: 10
      },
      columns: [
        { title: '告警事件名称', width: 200, ellipsis: true, scopedSlots: { customRender: 'name' } },
        { dataIndex: 'severity', title: '严重性', width: 160, scopedSlots: { customRender: 'severity' } },
        { dataIndex: 'triggered_at', title: '触发时间', width: 200 },
        { dataIndex: 'recovered_at', title: '恢复时间', width: 200 },
        { title: '持续时间', width: 160, scopedSlots: { customRender: 'duration' } },
        {
          dataIndex: 'recovered',
          title: '状态',
          width: 100,
          fixed: 'right',
          scopedSlots: { customRender: 'recovered' },
          filters: [
            { text: '恢复', value: 'true' },
            { text: '告警', value: 'false' }
          ],
          filterMultiple: false
        }
      ],
      dataSource: [],
      total: 0,
      loading: false,
      rowSelection: {
        selectedRowKeys: [],
        onChange: selectedRowKeys => {
          this.rowSelection.selectedRowKeys = selectedRowKeys
        }
      }
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => { sum += column.width })
      return sum
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.loading = true
      getAlertEventList(this.form).then(res => {
        const data = res.data
        this.dataSource = data.data
        this.total = data.total
      }).finally(() => {
        this.loading = false
      })
    },
    search () {
      this.form.page = 1
      this.rowSelection.selectedRowKeys = []
      this.fetch()
    },
    change (_, filters) {
      this.form.recovered = filters.recovered[0]
      this.fetch()
    },
    duration (triggeredAt, recoveredAt) {
      let second = 0
      if (recoveredAt) second = (moment(recoveredAt) - moment(triggeredAt)) / 1000
      else second = (moment() - moment(triggeredAt)) / 1000
      return humanTime(second)
    }
  }
}
</script>
